import React, { Component } from "react";
import BreadCrumb from "../components/BreadCrumb";
import { MdInfoOutline } from "react-icons/md";
import Config from "../Config";

class AlertLogSubscription  extends Component {

  state = {
    EmailId : null,
    AlertType : null,
    SubscribeResult : null
  };

  postSubscribe(){
    fetch(Config.AlertLogSubscriptionUrl,  {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ "emailid": this.state.EmailId, "alerttype": this.state.AlertType })
      }).then((response) => {
      if (response.ok) {
        return response.json();
      } 
      else {
        throw new Error(response.status);
      }
    })
    .then((data) => {
      this.setState({ SubscribeResult: "Success" });
    })
    .catch((error) => {
      this.setState( {SubscribeResult: "Failure"} )
  })};

  onSubscribe = () => {
    this.setState({ SubscribeResult: null });
    if(this.state.EmailId != null && 
        this.state.AlertType != null) {
      this.setState( {SubscribeResult: "Subscribing"} );
      this.postSubscribe();
    };
  };

  onEmailIdChange = (evt) => {
    this.setState({ EmailId: evt.target.value });
  };

  onAlertTypeChange = (evt) => {
    this.setState({ AlertType: evt.target.value });
  };

    render() {

        return (

        <div>

          <BreadCrumb currentpage="Alert Log Subscription" />

          <div class="row">
            <div class="col-sm bg-light">
                <small class="align-items-center text-info">
                <MdInfoOutline />&nbsp;Subscription to alert logs will receive email notification every morning at 9:00 AM pacific time. The notification will list the integration status of the factories for the past 24 hours.
                If you want to unsubscribe please contact Sms tech team at Lst-SM&S.SMSOnline.Support@nike.com.
                </small>
            </div>
         </div>

         <br />

          <div class="row">

            <div class="col-sm-4">
              <div class="input-group sm-3">
                <input type="text" class="form-control" placeholder="Recipient's username" 
                aria-label="Recipient's username" aria-describedby="basic-addon2"
                onChange={this.onEmailIdChange} />
                <div class="input-group-append">
                  <span class="input-group-text" id="basic-addon2">@nike.com</span>
                </div>
              </div>
            </div>

            <div class="col-sm-3 bg-light">
              <MdInfoOutline />&nbsp;<small class="text-muted align-items-center">Only nike.com email id is allowed.</small>
            </div>

          </div>

          <br />

          <div class="row">

            <div class="col-sm-4">
              <div class="input-group sm-3">
                <div class="input-group-prepend">
                  <label class="input-group-text" for="inputGroupSelect01">Alert Type</label>
                </div>
                <select class="custom-select" id="inputGroupSelect01" 
                 value={this.state.AlertType} onChange={this.onAlertTypeChange}>
                  <option selected>Choose...</option>
                  <option value="All">All</option>
                  <option value="Success">Success</option>
                  <option value="Failure">Failure</option>
                  <option value="Block">Block</option>
                  <option value="Manual Trigger">Manual Trigger</option>
                </select>
              </div>
            </div>

          </div>

          <br />

          <button class="btn  btn-outline-primary btn-sm" role="button" onClick={this.onSubscribe}>
              Subscribe
          </button>

          <br /> <br />

          {
              this.state.SubscribeResult == "Subscribing" ?

                <div class="alert alert-primary" role="alert">
                  Subscribing...please wait
                </div>

              : this.state.SubscribeResult == "Success" ?

                <div class="alert alert-success" role="alert">
                  Subscription confirmed.
                </div>

              : this.state.SubscribeResult == "Failure" ?
              
                <div class="alert alert-danger" role="alert">
                    Server error. Please try again later!
                </div>

              : null
          }

        </div>
            
        );
    };
};

export default AlertLogSubscription;