import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Home from "./Home";
import ManufacturingLocation from "./ManufacturingLocation";
import AlertLog from "./AlertLog";
import Block from "./Block";
import ManualTrigger from "./ManualTrigger";
import AlertLogSubscription from "./AlertLogSubscription";
import Watch from "./Watch";
import Failure from "./Failure";
import ProcessHistory from "./ProcessHistory";


const Body = () => {
  return (
      <div class="container-fluid">
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/Home" component={Home} />
            <Route path="/ManufacturingLocation" component={ManufacturingLocation} />
            <Route path="/AlertLog" component={AlertLog} />
            <Route path="/Block" component={Block} />
            <Route path="/ManualTrigger" component={ManualTrigger} />
            <Route path="/AlertLogSubscription" component={AlertLogSubscription} />
            <Route path="/Watch" component={Watch} />
            <Route path="/Failure" component={Failure} />
            <Route path="/ProcessHistory" component={ProcessHistory} />
          </Switch>
        </BrowserRouter>
      </div>
  );
};

export default Body;
