import React, { Component } from "react";
import BreadCrumb from "../components/BreadCrumb";
import { MdInfoOutline } from "react-icons/md";
import Config from "../Config";

class Watch  extends Component {

  state = {
    EmailId : null,
    LocationCode : null,
    WatchResult : null
  };

  postWatch(){
    fetch(Config.WatchUrl,  {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ "emailid": this.state.EmailId, "locationcode": this.state.LocationCode })
      }).then((response) => {
      if (response.ok) {
        return response.json();
      } 
      else {
        throw new Error(response.status);
      }
    })
    .then((data) => {
      this.setState({ WatchResult: "Success" });
    })
    .catch((error) => {
      this.setState( {WatchResult: "Failure"} )
  })};

  onWatch = () => {
    this.setState({ WatchResult: null });
    if(this.state.EmailId != null && 
        this.state.LocationCode != null) {
      this.setState( {WatchResult: "Watching"} );
      this.postWatch();
    };
  };

  onEmailIdChange = (evt) => {
    this.setState({ EmailId: evt.target.value });
  };

  onLocationCodeChange = (evt) => {
    this.setState({ LocationCode: evt.target.value });
  };

    render() {

        return (

        <div>

          <BreadCrumb currentpage="Watch" />

          <div class="row">
            <div class="col-sm bg-light">
                <small class="align-items-center text-info">
                <MdInfoOutline />&nbsp;You receive an instant email notification when things happen to a 
                manufacturing location in the integration process. For example, when a manufacturing location is blocked or 
                failed or succeeded during the integration, you'll be notified immediately. You will keep receiving the notification 
                until the manufacturing location is successfully integrated. 
                </small>
            </div>
         </div>

         <br />

          <div class="row">

            <div class="col-sm-4">
              <div class="input-group sm-3">
                <input type="text" class="form-control" placeholder="Recipient's username" 
                aria-label="Recipient's username" aria-describedby="basic-addon2"
                onChange={this.onEmailIdChange} />
                <div class="input-group-append">
                  <span class="input-group-text" id="basic-addon2">@nike.com</span>
                </div>
              </div>
            </div>

            <div class="col-sm-4 bg-light">
              <MdInfoOutline />&nbsp;<small class="text-muted align-items-center">Only nike.com email id is allowed.</small>
            </div>

          </div>

          <br />

          <div class="row">

            <div class="col-sm-4">

              <div class="input-group sm-3">
                <div class="input-group-append">
                  <span class="input-group-text" id="basic-addon2">Location Code</span>
                </div>
                <input type="text" class="form-control" placeholder="Manufacturing location code" 
                aria-label="Manufacturing location code" aria-describedby="basic-addon2"
                onChange={this.onLocationCodeChange} />
              </div>

            </div>

            <div class="col-sm-4 bg-light">
              <MdInfoOutline />&nbsp;<small class="text-muted align-items-center">Do not enter manufacturing location CR code or identifier.</small>
            </div>

          </div>

          <br />

          <button class="btn  btn-outline-primary btn-sm" role="button" onClick={this.onWatch}>
              Watch
          </button>

          <br /> <br />

          {
              this.state.WatchResult == "Watching" ?

                <div class="alert alert-primary" role="alert">
                  Setting up the watch...please wait
                </div>

              : this.state.WatchResult == "Success" ?

                <div class="alert alert-success" role="alert">
                  Watch setup completed.
                </div>

              : this.state.WatchResult == "Failure" ?
              
                <div class="alert alert-danger" role="alert">
                    Server error. Please try again later!
                </div>

              : null
          }

        </div>
            
        );
    };
};

export default Watch;