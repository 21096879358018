import React, { Component } from "react";
import { Link } from 'react-router-dom';

class BreadCrumb extends Component {

    render() {

        return (
            
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb bg-light">
                    <li class="breadcrumb-item">
                        <Link exact to="/Home" style={{ textDecoration: 'none' }}>Home</Link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">{this.props.currentpage}</li>
                </ol>
            </nav>
            
        );
    };
};

export default BreadCrumb;