import React, { Component } from "react";
import Config from "../Config";
import { MdInfoOutline } from "react-icons/md";

class ManualTriggerWindow  extends Component {

    state = {
      SetupResult : null
    };

    postSetup(){
      fetch(Config.ManualTriggerUrl,  {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ "vendorid": this.props.vendorid, "locationid": this.props.locationid })
        }).then((response) => {
        if (response.ok) {
          return response.json();
        } 
        else {
          throw new Error(response.status);
        }
      })
      .then((data) => {
        this.setState({ SetupResult: "Success" });
      })
      .catch((error) => {
        this.setState( {SetupResult: "Failure"} )
    })};

    onSetup = () => {
      this.setState( {SetupResult: "Settingup"} );
      this.postSetup();
    };

    render() {

        return (

            <div class="modal fade" id="ManualTriggerWindow" tabindex="-1" role="dialog" aria-labelledby="ModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-scrollable" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="ModalLabel">{this.props.title}</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <span>Are you sure you want to setup <b>{this.props.locationname}</b> for integration?</span>
                  <div class="row">
                    <div class="col-sm">
                        <small class="text-info align-items-center"><MdInfoOutline />&nbsp;This action cannot be undone.</small>
                    </div>
                  </div>
                    {
                        this.state.SetupResult == "Settingup" ?

                          <div class="alert alert-primary" role="alert">
                            Setting up...please wait
                          </div>

                        : this.state.SetupResult == "Success" ?

                          <div class="alert alert-success" role="alert">
                            Manufacturing location is set for integration.
                          </div>

                        : this.state.SetupResult == "Failure" ?
                        
                          <div class="alert alert-danger" role="alert">
                              Server error. Please try again later!
                          </div>

                        : null
                    }
                </div>
                <div class="modal-footer">
                <button type="button" class="btn-primary btn-sm" onClick={this.onSetup}>Setup</button>
                  <button type="button" class="btn-secondary btn-sm" data-dismiss="modal">Close</button>
                </div>
              </div>
            </div>
          </div>
            
        );
    };
};

export default ManualTriggerWindow;