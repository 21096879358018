import React, { Component } from "react";

class LocationStatus extends Component {

    render() {

        return (

        <div class="row">

            <div class="col-sm">
                <ul class="list-group">
                    <li class="list-group-item" style={{backgroundColor: "#563d7c", color: "#ffffff"}}>Aravo</li>
                    <li class="list-group-item">Approved</li>
                    <li class="list-group-item">Divest Decided</li>
                    <li class="list-group-item">Divested</li>
                    <li class="list-group-item">Limited Approval</li>
                    <li class="list-group-item">Not Registered</li>
                    <li class="list-group-item">Registered</li>
                    <li class="list-group-item">Terminated</li>
                    <li class="list-group-item">Under Evaluation</li>
                    <li class="list-group-item">Not Approved</li>
                </ul>
            </div>

            <div class="col-sm">
                <ul class="list-group">
                    <li class="list-group-item" style={{backgroundColor: "#563d7c", color: "#ffffff"}}>Sms</li>
                    <li class="list-group-item">Active</li>
                    <li class="list-group-item">Active</li>
                    <li class="list-group-item">Divested</li>
                    <li class="list-group-item">Pending</li>
                    <li class="list-group-item">Pending</li>
                    <li class="list-group-item">Pending</li>
                    <li class="list-group-item">Divested</li>
                    <li class="list-group-item">Pending</li>
                    <li class="list-group-item">Rejected</li>
                </ul>
            </div>

        </div>
            
        );
    };
};

export default LocationStatus;