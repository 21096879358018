import React, { Component } from "react";
import BreadCrumb from "../components/BreadCrumb";
import Config from "../Config";
import FailureList from "../components/FailureList";

class Failure extends Component {

  state = {
    FailureData : null,
    FailureResult : "Loading"
  };

  getFailure(){
    fetch(Config.FailureUrl).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(response.status);
      }
    })
    .then((data) => {
      this.setState({ FailureData: data });
      this.setState({ FailureResult: "Success" });
    })
    .catch((error) => {
      this.setState( {FailureResult: "Failure"} )
  })};

  componentDidMount() {
    this.getFailure();
  };

  render() {

    return (
    
      <div>

        <BreadCrumb currentpage="Failure" />

        <div>

          <FailureList list= {this.state.FailureData}
                    status= {this.state.FailureResult} />

        </div>

      </div>
      
    );

  };

};

export default Failure;
