import React, { Component } from "react";

class Alert extends Component {

    render() {

        return (
            
            <div class={this.props.styleClass} role="alert">
                {this.props.message}
            </div>
            
        );
    };
};

export default Alert;