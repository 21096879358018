import React, { Component } from "react";
import Card from "../components/Card";
import ListCard from "../components/ListCard"; 
import { MdRefresh } from "react-icons/md";
import Config from "../Config";
import { MdInfoOutline } from "react-icons/md";

class Home extends Component {

      state = {
        IntegrationStatusData : [],
        IntegrationStatusResult: "Loading",
        ServiceStatusData : []
      };

      getRefreshDate(){
        const currentdate = new Date(); 
        const refreshDate = (currentdate.getMonth()+1)  + "/" 
                        + currentdate.getDate() + "/"
                        + currentdate.getFullYear() + " at "  
                        + currentdate.getHours() + ":"  
                        + currentdate.getMinutes() + ":" 
                        + currentdate.getSeconds(); 
        return refreshDate;
      };

      getIntegrationStatus(){
        fetch(Config.IntegrationStatusUrl).then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error(response.status);
          }
        })
        .then((data) => {
          this.setState({ IntegrationStatusData: data });
          this.setState({ IntegrationStatusResult: "Success" });
        })
        .catch((error) => {
          this.setState( {IntegrationStatusResult: "Failure"} )
      })};

      getServiceStatus(){
        this.setState( {ServiceStatusResult: "Loading"} );
        fetch(Config.ServiceStatusUrl).then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error(response.status);
          }
        })
        .then((data) => {
          this.setState({ ServiceStatusData: data });
          this.setState({ ServiceStatusResult: "Success" });
        })
        .catch((error) => {
          this.setState( {ServiceStatusResult: "Failure"} )
      })};


      componentDidMount() {
        this.getIntegrationStatus();
        this.getServiceStatus();
      };
         
      onRefresh = () => {
        this.setState( {IntegrationStatusResult: "Loading"} );
        this.getIntegrationStatus();
      };

    render() {
  
      return (

      <div>

        <div class="row">
          <div class="col-sm-4 offset-sm-8 bg-light">
              <small class="text-info align-items-center"><MdInfoOutline />&nbsp;The integration status is refreshed on {this.getRefreshDate()}.</small>
              <button type="button" class="btn btn-sm" onClick={this.onRefresh}>
                <MdRefresh />
              </button>
          </div>
        </div>

        <div class="row">
            
            <div class="col-8">

                <div class="card-deck">

                    <Card title="Manufacturing Location"
                        body="Search and find the integration status of a manufacturing location in the data integration pipeline." 
                        action="Search"
                        link="/manufacturinglocation"  />
                
                    <Card title="Block"
                        body="View the currently blocked manufacturing locations in the data integration pipeline." 
                        action="Block"
                        link="/block"  /> 

                    <Card title="Manual Trigger"
                        body="Set up a manufacturing location to integrate into Sms." 
                        action="Manual Trigger"
                        link="/ManualTrigger"  /> 

                    

                </div>
                <br />
                <div class="card-deck">

                    <Card title="Alert Log"
                        body="Search and view the alert history logs of a manufacturing location in the data integration pipeline." 
                        action="Search"
                        link="/alertlog"  /> 

                    <Card title="Alert Log Subscription"
                        body="Subscribe to the alert logs in the data integration pipeline." 
                        action="Subscribe"
                        link="/AlertLogSubscription"  />

                    <Card title="Watch"
                        body="Follow a manufacturing location in the data integration pipeline." 
                        action="Watch"
                        link="/Watch"  /> 
                </div>
                <br />
                <div class="card-deck">

                  <Card title="Failure"
                        body="View the currently failed vendors and manufacturing locations in the data integration pipeline." 
                        action="Failure"
                        link="/failure"  /> 
                  
                  <Card title="Process History"
                        body="Search and view the process history of a vendor or manufacturing location in the data integration pipeline." 
                        action="Search"
                        link="/processHistory"  /> 

                </div>
            </div>
            <div class="col-4">
               
                <div class="card-deck">
                    <ListCard title="Integration Status"
                    list= {this.state.IntegrationStatusData}
                    status= {this.state.IntegrationStatusResult} /> 
                </div>

                <div class="card-deck">
                    <ListCard title="System Status"
                    list= {this.state.ServiceStatusData}
                    status= {this.state.ServiceStatusResult} />  
                </div>

            </div>
            
        </div>

      </div>   

      );
  
    };
  
  };
  
  export default Home;