import React, { Component } from "react";
import Header from "./containers/Header";
import Body from "./containers/Body";
import Unauthorized from "./containers/Unauthorized";

class App extends Component {
  render() {
    return (
      <div>
         {
          this.props.role === "App.Sms-Aravo-Data-Integration-Dashboard.Admin" ? 
          <div>
            <Header  user={this.props.user} />
            <br />
            <Body />
            <br />
          </div>
          :<Unauthorized />
         }
      </div>
    );
  };
};

export default App;