import React, { Component } from 'react';
import { FaProjectDiagram } from "react-icons/fa";
import DataIntegrationDiagram from '../assets/DataIntegrationDiagram.pdf';

class DownloadDataIntegrationDiagram extends Component {

  render() {

    return (
        <a style={{ textDecoration: 'none', color: "#C0C0C0" }} href={DataIntegrationDiagram} 
        target="_blank" data-toggle="tooltip" data-placement="bottom" title="Data Integration Diagram"><FaProjectDiagram /></a>
    );
  }
}

export default DownloadDataIntegrationDiagram;