import React, { Component } from "react";
import { MdInfoOutline } from "react-icons/md";

class ProcessHistorySearchResult extends Component {

    render() {

        return (

        <div>

            <div class="row">
                <div class="col-sm bg-light">
                    <small class="text-info align-items-center"><MdInfoOutline />&nbsp;The search result is limited to 100 records with the latest process history on top of the list.</small>
                </div>
            </div>
            
            <table class="table table-sm">
                <thead>
                    <tr>
                        <th scope="col" style={{width: "10%"}}>Vendor Id</th>
                        <th scope="col" style={{width: "10%"}}>Location Id</th>
                        <th scope="col" style={{width: "10%"}}>Location Code</th>
                        <th scope="col" style={{width: "50%"}}>Location Name</th>
                        <th scope="col" style={{width: "20%"}}>Process Date</th>
                    </tr>
                </thead>
                <tbody>
                    
                    {this.props.list.map(item => (
                        <tr>
                            <td style={{width: "10%"}}>{item.VendorId}</td>
                            <td style={{width: "10%"}}>{item.LocationId}</td>
                            <td style={{width: "10%"}}>{item.LocationCode}</td>
                            <td style={{width: "50%"}}>{item.LocationName}</td>
                            <td style={{width: "20%"}}>{item.ProcessedDate}</td>
                        </tr>
                    ))}
                   
                </tbody>
            </table>

        </div>
            
        );
    };
};

export default ProcessHistorySearchResult;