import React, { Component } from "react";

class LocalStatusWindow  extends Component {

    render() {

        return (

            <div class="modal fade" id="LocationStatusWindow" tabindex="-1" role="dialog" aria-labelledby="ModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-scrollable" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="ModalLabel">{this.props.title}</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  {this.props.body}
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn-primary btn-sm" data-dismiss="modal">Close</button>
                </div>
              </div>
            </div>
          </div>
            
        );
    };
};

export default LocalStatusWindow;