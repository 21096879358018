import React, { Component } from "react";
import Config from "../Config";

class ModalUnblockForm extends Component {

    state = {
        sourceReasonList : [],
        sourcingGroupList : [],
        unblock : [],
        selectedProductEngine : null,
        selectedBrand: null,
        selectedSourceReason: null,
        selectedSourcingType: null,
        selectedSourcingGroup: null,
        postStatus : null,
        getStatus : null
    };

    getSourceReasonList(){
        fetch(Config.SourceReasonListUrl).then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error(response.status);
          }
        })
        .then((data) => {
          this.setState({ sourceReasonList: data});
          this.setState({ getStatus: "Success" });
        })
        .catch((error) => {
          this.setState( {getStatus: "Failure"} )
    })};

    getSourcingGroupList(){
        fetch(Config.SourcingGroupListUrl).then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error(response.status);
          }
        })
        .then((data) => {
          this.setState({ sourcingGroupList: data});
          this.setState({ getStatus: "Success" });
        })
        .catch((error) => {
          this.setState( {getStatus: "Failure"} )
    })};

    componentDidMount() {
      this.getSourceReasonList();
      this.getSourcingGroupList();
    };

    onUnblock = () => {
      this.setState({ postStatus: "Posting" });
      this.setState( {unblock: []} );
      this.state.unblock.push(
        {
            "ManufacturingLocationGvid": this.props.selectedLocationId,
            "SourceReason": this.state.selectedSourceReason,
            "SourcingGroup": this.state.selectedSourcingGroup,
            "AgreementType": this.state.selectedSourcingType,
            "BrandName": this.state.selectedBrand,
            "ProductEngineDescription": this.state.selectedProductEngine
        });
        this.unblock();
    };

    unblock(){
        fetch(Config.UnblockUrl,  {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ "unblock": this.state.unblock })
          }).then((response) => {
          if (response.ok) {
            return response.json();
          } 
          else {
            throw new Error(response.status);
          }
        })
        .then((data) => {
          this.setState({ postStatus: "Success" });
          window.location.reload(false);
        })
        .catch((error) => {
          this.setState( {postStatus: "Failure"} )
          console.log("Failure");
    })};


    render() {

        return (

        <div class="modal fade" id="Unblock" tabindex="-1" role="dialog" aria-labelledby="UnblockModalTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered  modal-dialog-scrollable modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h6 class="modal-title" id="UnblockModalTitle">Unblock - {this.props.selectedLocationName} [{this.props.selectedLocationId}]</h6>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form>
                            <div class="form-row">
                                <div class="form-group col-sm-6">
                                    <label for="ProductEngine">Product Engine</label>
                                    <select class="form-control" id="ProductEngine"
                                     value={this.state.selectedProductEngine}
                                     onChange={(e) => this.setState({selectedProductEngine: e.target.value})}>
                                        <option value="0" selected>Choose...</option>
                                        <option value="Apparel">Apparel</option>
                                        <option value="Equipment">Equipment</option>
                                        <option value="Footwear">Footwear</option>
                                    </select>
                                </div>
                                <div class="form-group col-sm-6">
                                    <label for="Brand">Brand</label>
                                    <select class="form-control" id="Brand"
                                    value={this.state.selectedBrand}
                                    onChange={(e) => this.setState({selectedBrand: e.target.value})}>
                                        <option value="0" selected>Choose...</option>
                                        <option value="Converse">Converse</option>
                                        <option value="Nike">Nike</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-sm-6">
                                    <label for="SourceReason">Source Reason</label>
                                    <select class="form-control" id="SourceReason" 
                                    value={this.state.selectedSourceReason}
                                    onChange={(e) => this.setState({selectedSourceReason: e.target.value})}>
                                       <option value="0" selected>Choose...</option>
                                      {this.state.sourceReasonList.map((item) => <option key={item.Id} value={item.SourceReason}>{item.SourceReason}</option>)}
                                    </select>
                                </div>
                                <div class="form-group col-sm-6">
                                    <label for="SourcingType">Sourcing Type</label>
                                    <select class="form-control" id="SourcingType"
                                     value={this.state.selectedSourcingType}
                                     onChange={(e) => this.setState({selectedSourcingType: e.target.value})}>
                                         <option value="0" selected>Choose...</option>
                                        <option value="Inline">Inline</option>
                                        <option value="Licensee">Licensee</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-sm-12">
                                    <label for="SourcingGroup">Sourcing Group</label>
                                    <select class="form-control" id="SourcingGroup"
                                    value={this.state.selectedSourcingGroup}
                                    onChange={(e) => this.setState({selectedSourcingGroup: e.target.value})}>
                                     <option value="0" selected>Choose...</option>
                                     {this.state.sourcingGroupList.map((item) => <option key={item.Id} value={item.SourcingGroup}>{item.SourcingGroup}</option>)}
                                    </select>
                                </div>
                            </div>
                        </form>

                        {
                                this.state.postStatus === "Posting" ?

                                <div class="alert alert-info" role="alert">
                                    Unblocking...please wait
                                </div>

                                : this.state.postStatus === "Success" ?

                                <div class="alert alert-success" role="alert">
                                    Unblocked successfully.
                                </div>

                                : this.state.postStatus === "Failure" ?
                                
                                <div class="alert alert-danger" role="alert">
                                    Something went wrong while unblocking the manufacturing location! Please try again later.
                                </div>

                                : null
                        }

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal">Close</button>
                       { 
                           this.props.selectedLocationId !== null &&
                           this.state.selectedSourceReason !== null &&
                           this.state.selectedSourcingGroup !== null &&
                           this.state.selectedSourcingType !== null &&
                           this.state.selectedBrand !== null &&
                           this.state.selectedProductEngine !== null ?
                          <button type="button" class="btn btn-sm btn-primary" onClick={this.onUnblock}>Unblock</button>
                          : <button type="button" class="btn btn-sm btn-primary" disabled>Unblock</button>
                      }
                    </div>
                </div>
            </div>
        </div>

        );
    };
};

export default ModalUnblockForm;