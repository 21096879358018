import React, { Component } from "react";
import { MdSubject } from "react-icons/md";
import { MdStars } from "react-icons/md";


class ListCard extends Component {

    render() {

      let list;
      if (this.props.status == "Loading") {
        list =  <div class="alert alert-primary" role="alert">
                  Loading...
                </div>
      }
     
      if(this.props.status == "Success") {
        list = this.props.list.map(item => (
              <li class="list-group-item border-white d-flex justify-content-between align-items-center">
                  {item.Key}
                  <span className={item.Styleclass}>{item.Value}</span>
              </li>
              ))
      }

      if(this.props.status == "Failure") {
        list = <div class="alert alert-danger" role="alert">
                  Server error. Please try again later!
                </div>
      }

        return (

            <div class="card border-white">
              <div class="card-body">
                <h6 class="card-title">
                  {
                    {
                      "Integration Status": <MdSubject />,
                      "System Status": <MdStars />
                    }[this.props.title]
                  }
                  &nbsp;{this.props.title}
                </h6>
                <div class="border-top my-2"></div>
                <ul class="list-group list-group-flush">  
                  {list}
                </ul>
              </div>
            </div>
            
        );
    };
};

export default ListCard;