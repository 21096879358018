import React, { Component } from "react";
import { withOktaAuth } from "@okta/okta-react";
import App from "./App";

async function checkUser() {
  if (this.props.authState.isAuthenticated && this.state.userInfo === null) {
    const userInfo = await this.props.authService.getUser();
    if (this._isMounted) {
      this.setState({ userInfo });
    }
  }
}

export default withOktaAuth(class Login extends Component {

  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = { userInfo: null };
    this.checkUser = checkUser.bind(this);
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
  }

  async login() {
    this.props.authService.login("/");
  }

  async logout() {
    this.props.authService.logout("/");
  }

  async componentDidMount() {
    this._isMounted = true;
    this.checkUser();
  }

  async componentDidUpdate() {
    this._isMounted = true;
    this.checkUser();
    console.log(this.state.userInfo);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    if (this.props.authState.isPending) 
      return <div>Loading...</div>;
    return this.props.authState.isAuthenticated ?
      <div>
        {this.state.userInfo !== null ? <App role={this.state.userInfo.groups[0]} user={this.state.userInfo.name} /> : null}
      </div>
     :
      <div>
        {this.login()} 
      </div>
  }
});