
const Dev = {
    ClientId: "nike.gsm.sms-aravo-data-integration-dashboard",
    OktaAuthorizeUrl: "https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7",
    OktaTokenUrl: "https://api.aegis.nikecloud.com/v1/qa/token",
    AravoUrl : "https://vendorportal.nike.com/aems/login.do",
    SmsoUrl : "http://hillsbor-svr-qg:4321/SMSHome.aspx",
    IntegrationStatusUrl : "https://5yrjze1kqc.execute-api.us-west-2.amazonaws.com/dev/integrationstatus",
    ServiceStatusUrl : "https://5yrjze1kqc.execute-api.us-west-2.amazonaws.com/dev/servicestatus",
    ManufacturingLocationUrl : "https://5yrjze1kqc.execute-api.us-west-2.amazonaws.com/dev/manufacturinglocation?searchparam=",
    AlertLogUrl : "https://5yrjze1kqc.execute-api.us-west-2.amazonaws.com/dev/alertlog?searchparam=",
    BlockUrl : "https://5yrjze1kqc.execute-api.us-west-2.amazonaws.com/dev/block",
    ManualTriggerUrl : "https://5yrjze1kqc.execute-api.us-west-2.amazonaws.com/dev/manualtrigger?searchparam=",
    AlertLogSubscriptionUrl : "https://5yrjze1kqc.execute-api.us-west-2.amazonaws.com/dev/alertlogsubscription",
    WatchUrl : "https://5yrjze1kqc.execute-api.us-west-2.amazonaws.com/dev/watch",
    FailureUrl : "https://5yrjze1kqc.execute-api.us-west-2.amazonaws.com/dev/failure",
    ProcessHistoryUrl : "https://5yrjze1kqc.execute-api.us-west-2.amazonaws.com/dev/processHistory?searchparam=",
    SourceReasonListUrl : "https://5yrjze1kqc.execute-api.us-west-2.amazonaws.com/dev/sourcereason",
    SourcingGroupListUrl : "https://5yrjze1kqc.execute-api.us-west-2.amazonaws.com/dev/sourcinggroup",
    UnblockUrl : "https://5yrjze1kqc.execute-api.us-west-2.amazonaws.com/dev/unblock"
};

const Prod = {
    ClientId: "nike.gsm.sms-aravo-data-integration-dashboard",
    OktaAuthorizeUrl: "https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7",
    OktaTokenUrl: "https://api.aegis.nikecloud.com/v1/prod/token",
    AravoUrl : "https://vendorportalsso.nike.com/aems/login.do",
    SmsoUrl : "http://compliance.nike.com/SMSHome.aspx",
    IntegrationStatusUrl : "https://vyg3j4edok.execute-api.us-west-2.amazonaws.com/prod/integrationstatus",
    ServiceStatusUrl : "https://vyg3j4edok.execute-api.us-west-2.amazonaws.com/prod/servicestatus",
    ManufacturingLocationUrl : "https://vyg3j4edok.execute-api.us-west-2.amazonaws.com/prod/manufacturinglocation?searchparam=",
    AlertLogUrl : "https://vyg3j4edok.execute-api.us-west-2.amazonaws.com/prod/alertlog?searchparam=",
    BlockUrl : "https://vyg3j4edok.execute-api.us-west-2.amazonaws.com/prod/block",
    ManualTriggerUrl : "https://vyg3j4edok.execute-api.us-west-2.amazonaws.com/prod/manualtrigger?searchparam=",
    AlertLogSubscriptionUrl : "https://vyg3j4edok.execute-api.us-west-2.amazonaws.com/prod/alertlogsubscription",
    WatchUrl : "https://vyg3j4edok.execute-api.us-west-2.amazonaws.com/prod/watch",
    FailureUrl : "https://vyg3j4edok.execute-api.us-west-2.amazonaws.com/prod/failure",
    ProcessHistoryUrl : "https://vyg3j4edok.execute-api.us-west-2.amazonaws.com/prod/processHistory?searchparam=",
    SourceReasonListUrl : "https://vyg3j4edok.execute-api.us-west-2.amazonaws.com/prod/sourcereason",
    SourcingGroupListUrl : "https://vyg3j4edok.execute-api.us-west-2.amazonaws.com/prod/sourcinggroup",
    UnblockUrl : "https://vyg3j4edok.execute-api.us-west-2.amazonaws.com/prod/unblock"
};

const Config = Dev;

module.exports = Config;
