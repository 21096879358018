import React, { Component } from "react";
import BreadCrumb from "../components/BreadCrumb";
import Config from "../Config";
import BlockList from "../components/BlockList";

class Block extends Component {

  state = {
    BlockData : null,
    BlockResult : "Loading"
  };

  getBlock(){
    fetch(Config.BlockUrl).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(response.status);
      }
    })
    .then((data) => {
      this.setState({ BlockData: data });
      this.setState({ BlockResult: "Success" });
    })
    .catch((error) => {
      this.setState( {BlockResult: "Failure"} )
  })};

  componentDidMount() {
    this.getBlock();
  };

  render() {

    return (
    
      <div>

        <BreadCrumb currentpage="Block" />

        <div>

          <BlockList list= {this.state.BlockData}
                    status= {this.state.BlockResult} />

        </div>

      </div>
      
    );

  };

};

export default Block;
