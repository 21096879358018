import React, { Component } from "react";
import { MdDashboard } from "react-icons/md";
import { FaBook } from "react-icons/fa";
import { FaUserCircle } from "react-icons/fa";
import Config from "../Config";
import LocalStatusWindow from "../components/LocationStatusWindow";
import LocationStatus from "../components/LocationStatus";
import DownloadDataIntegrationDiagram from "../components/DownloadDataIntegrationDiagram";

class Header extends Component {

  render() {

    return (
        <div>
            <nav class="navbar navbar-expand-lg" style={{backgroundColor: "#563d7c", color: "#ffffff"}}>
                <MdDashboard />&nbsp;Sms Aravo Data Integration Dashboard
                <span class="navbar-nav mr-auto"></span>
                <DownloadDataIntegrationDiagram />&nbsp;&nbsp;
                <a style={{ textDecoration: 'none', color: "#C0C0C0" }} href="#" 
                target="_blank" data-toggle="tooltip" data-placement="bottom" data-toggle="modal" data-target="#LocationStatusWindow" title="Manufacturing Location Status Mapping"><FaBook /></a>&nbsp;&nbsp;
                <a class="btn btn-outline-warning btn-sm" href={Config.AravoUrl} target="_blank">Aravo</a>&nbsp;
                <a class="btn btn-outline-warning btn-sm" href={Config.SmsoUrl} target="_blank">Smso</a>&nbsp;
            </nav>
            <div class="container-fluid">
              <div class="row border border-light bg-light">
                <div class="col-md-6">
                &nbsp;
                </div>
                <div class="col-md-4 offset-md-2 text-right">
                  <small class="font-weight-bold text-muted"><FaUserCircle />&nbsp;
                  {
                    this.props.user
                  }
                  </small>
                </div>
              </div>
            </div>
            <LocalStatusWindow title="Manufacturing Location Status Mapping"
            body={<LocationStatus />} />
        
        </div>
    );

  };

};

export default Header;
